import React from "react"
import {Helmet} from "react-helmet-async"
import {graphql} from "gatsby"
import {RichText} from "prismic-reactjs"
import {withPrismicPreview} from 'gatsby-plugin-prismic-previews'

import {GatsbySeo} from "gatsby-plugin-next-seo"

import {Link} from "gatsby"
import Layout from "../components/layout"
import "/src/styles/flutter-blog.css"
import HeaderH1 from "../components/headerH1";
import {GatsbyImage} from "gatsby-plugin-image"

import Highlight, {defaultProps} from 'prism-react-renderer';
import Prism from 'prism-react-renderer/prism';
import theme from 'prism-react-renderer/themes/dracula';
import {
  LineNo,
  Pre,
} from './styles';

var PrismicDOM = require("prismic-dom")
const {linkResolver} = require("../utils/link-resolver")


require('prismjs/components/prism-dart');

(typeof global !== 'undefined' ? global : window).Prism = Prism;


const htmlSerializer = (type, element, content, children) => {
  if (type === 'preformatted') {
    let language = "dart";

    // children is an array of an array. that's why we do a flatmap
    const elementAsString = children.flatMap((ele) => {
      return ele
    }).map((ele) => {
      if (typeof ele == "object") {
        if (ele.type === "br") {
          return "\n";
        }
        return "";
      }
      // get the language from the parameter //---
      try {
        if (ele.startsWith("//---")) {
          language = ele.substr(5)
          return "";
        }
      } catch (e) {
        console.error(e)
      }
      return ele
    }).join("");

    return (
      <Highlight
        {...defaultProps}
        Prism={Prism}
        code={elementAsString ?? ""}
        language={language}
        theme={theme}
      >
        {({
            className: blockClassName,
            style,
            tokens,
            getLineProps,
            getTokenProps,
          }) => (
          <Pre
            className={blockClassName}
            style={style}
            hasLanguage={!!language}
          >
            <code>
              {tokens.map((line, index) => {
                const lineProps = getLineProps({line, key: index});
                return (
                  <div {...lineProps}>
                    {<LineNo>{index + 1}</LineNo>}
                    {line.map((token, key) => (
                      <span {...getTokenProps({token, key})} />
                    ))}
                  </div>
                );
              })}
            </code>
          </Pre>
        )}
      </Highlight>)

  }

  return null;
}

function countWords(str) {
  if (str) {
    str = str.replace(/(^\s*)|(\s*$)/gi, "")
    str = str.replace(/[ ]{2,}/gi, " ")
    str = str.replace(/\n /, "\n")
    return str.split(" ").length
  }
  return 150
}

function calcluateReadingTime(str) {
  return Math.round(countWords(str) / 150)
}

function seoImageList(image, url) {
  if (image && url) {
    return {
      url: url,
      width: image.dimensions.width,
      height: image.dimensions.height,
    }
  }
  return {}
}

const FlutterBlogTemplate = ({data}) => {
  if (!data) return null

  const node = data.prismicFlutterPost
  const document = node.data

  const readingTime = calcluateReadingTime(document.rich_text.text)

  var last_publication_date = PrismicDOM.Date(node.last_publication_date)

  var last_publication_date_formatted = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(last_publication_date)

  var first_publication_date = PrismicDOM.Date(node.first_publication_date)

  var first_publication_date_formatted = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(first_publication_date)

  let description = document.meta_description?.text;
  if (description === undefined || description === "") {
    description = document.rich_text.text.substring(0, 150);
  }

  const shareableImageUrl = `https://www.linkfive.io${document.hero_image?.localFile?.childImageSharp?.fluid?.src}`

  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <GatsbySeo
        title={document.title.text + " - Flutter Subscription Blog"}
        description={description}
        canonical={"https://www.linkfive.io/flutter-blog/" + node.uid}
        openGraph={{
          url: "https://www.linkfive.io/flutter-blog/" + node.uid,
          local: node.lang,
          type: "article",
          article: {
            publishedTime: node.first_publication_date,
            modifiedTime: node.last_publication_date,
          },
          images: [seoImageList(document.hero_image, shareableImageUrl)],
        }}
      />
      <Helmet>
        <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${document.title.text}",
      "image": [
        "${shareableImageUrl}"
       ],
      "datePublished": "${node.first_publication_date}",
      "dateModified": "${node.last_publication_date}",
      "author": [{
          "@type": "Person",
          "name": "${document.author_name.text}"
        }]
    }
          `}</script>
      </Helmet>
      <section className="bg-white py-6 mt-12 ">
        <div className="container mx-auto">
          <Link to="/flutter-blog" className="inline-block py-2 px-4 font-bold nav-underline">&#x2190; Back to
            the blog</Link>
        </div>
      </section>
      <section className="bg-white pb-6">
        {document.hero_image && document.hero_image.localFile ? (
          <GatsbyImage className="lg:max-w-2xl max-w-md mx-auto"
                       image={document.hero_image.localFile.childImageSharp.gatsbyImageData}
                       alt="Hero Image"/>
        ) : (
          <></>
        )}

        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <HeaderH1 headerText={document.title.text} widthClass="lg:w-4/6"/>
          <div className="max-w-5xl mx-auto m-8 text-gray-500 md:text-xl longform-text blog_content">
            <p className="text-center italic">
              By {document.author_name.text} - Average reading time:{" "}
              {readingTime} minutes
            </p>
            <article className="max-w-3xl mx-auto">
              <RichText render={document.rich_text.raw} htmlSerializer={htmlSerializer} linkResolver={linkResolver}/>
            </article>
          </div>
        </div>

        <div className="w-2/3 mx-auto pb-20">
          <hr className="border-solid border-2 border-l5-primary mb-5"/>
          <p className="text-center text-gray-400">
            {node.last_publication_date !== node.first_publication_date ? (
              <span>
                First published on {first_publication_date_formatted}, last
                updated on {last_publication_date_formatted}
              </span>
            ) : (
              <span>Published on {first_publication_date_formatted}</span>
            )}
            {document.image_credits && document.image_credits ? (
              <span className="italic">
                {" "}
                &#8212; Image credits: {document.image_credits}
              </span>
            ) : (
              <></>
            )}
          </p>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query FlutterBlogPageQuery($uid: String) {
    prismicFlutterPost(uid: { eq: $uid }) {
      _previewable
      uid
      first_publication_date
      last_publication_date
      lang
      data {
        rich_text {
          text
          raw
        }
        title {
          text
        }
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid {
                aspectRatio
                src
                srcSet
                sizes
                base64
                srcSetWebp
              }
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          dimensions {
            width
            height
          }
        }
        author_name {
          text
        }
        
        meta_description {
          text
        }
        meta_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(FlutterBlogTemplate, [
  {
    repositoryName: 'linkfive',
    linkResolver,
  },
])
